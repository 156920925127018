<template>
  <div class="small-screen-navigation">
    <div class="top">
      <HomeLink class="homelink" />
      <div class="actions">
        <button class="hamburger" type="button" @click="closeMenu()">
          <span class="label text-sm">{{ $t('navigation.close') }}</span>
          <svg
            class="icon"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.0118 1.98816L1.98804 22.0119"
              stroke="currentColor"
              stroke-width="1.71"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.98804 1.98816L22.0118 22.0119"
              stroke="currentColor"
              stroke-width="1.71"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
    <MainNavigation :key="locale" class="mainnavigation" />
    <ul class="links text-lg-vs">
      <li
        v-for="link in siteInfo?.legalInformationLinks"
        :key="`legal-information-link-${link.id}`"
        class="item"
      >
        <Link :link="link" />
      </li>
    </ul>
    <div class="bottom">
      <LangSwitch :key="locale" class="langswitch" />
      <ColorSchemeSwitch class="colorschemeswitch" />
    </div>
  </div>
</template>

<script setup lang="ts">
import LangSwitch from '~/components/site-header/LangSwitch.vue';
import MainNavigation from '~/components/site-header/MainNavigation.vue';
import HomeLink from '~/components/partials/HomeLink.vue';
import Link from '~/components/partials/Link.vue';
import { useGlobalData } from '~/datocms/useGlobalData';

const { locale } = useI18n();
const { siteInfo } = useGlobalData();

function closeMenu() {
  const els = document.querySelectorAll('.site-header-content');
  els.forEach((el) => {
    el.classList.remove('-is-open');
  });
}
</script>

<style scoped lang="scss">
.small-screen-navigation {
  @keyframes show-small-nav {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @media (--vs) {
    display: none;
    flex-direction: column;
    background-color: var(--base-color-background);
    background-image: light-dark(
      none,
      url('/public/images/background-home-small-dark.svg')
    );
    background-position: top center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: calc(var(--z-overlay) + 1);
    padding: 1.688rem 1.5rem;

    > .top {
      display: grid;
      align-items: center;
      grid-template-columns: repeat(2, 1fr);

      > .actions > .hamburger {
        display: grid;
        align-items: center;
        grid-template-columns: min-content 1.5rem;
        gap: 0.75rem;
        appearance: none;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        margin-left: auto;
        flex-shrink: 0;
        cursor: pointer;
        color: var(--base-color-foreground-faded);

        > .label {
          white-space: nowrap;
        }

        > .icon {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    > .mainnavigation {
      margin-top: auto;
    }

    > .links {
      margin-top: auto;
      display: grid;
      gap: 1.5rem;

      > .item > .link {
        color: var(--base-color-foreground-faded);
        text-decoration: none;
      }
    }

    > .bottom {
      display: flex;
      justify-content: end;
      margin-top: auto;
    }
  }

  @media (--vl) {
    display: none;
  }

  body:has(.site-header-content.-is-open) & {
    @media (--vs) {
      display: flex;
      animation: show-small-nav var(--base-transition-duration)
        var(--base-transition-easing) forwards;
    }
  }
}
</style>

<style lang="scss">
.small-screen-navigation {
  @keyframes fade-in {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (--vs) {
    > .mainnavigation > .list > .item,
    > .links > .item {
      opacity: 0;
      transform: translateY(2rem);

      &:nth-child(2) {
        animation-delay: 0.125s;
      }

      &:nth-child(3) {
        animation-delay: 0.25s;
      }

      body:has(.site-header-content.-is-open) & {
        animation-name: fade-in;
        animation-duration: var(--base-transition-duration-slow);
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
      }
    }

    > .links > .item {
      &:nth-child(1) {
        animation-delay: 0.5s;
      }

      &:nth-child(2) {
        animation-delay: 0.625s;
      }
    }
  }
}

body:has(.site-header-content.-is-open) {
  @media (--vs) {
    overflow-y: hidden;
  }
}
</style>
