<template>
  <div class="home-link">
    <!-- TODO: link properly -->
    <NuxtLink
      v-if="siteInfo"
      :to="{ ...getRoute('home', 'HomePageRecord') }"
      class="link text-sm text-sm-vl"
    >
      <img class="logo" src="/images/logos/vbsa.svg" alt="Logo VBSA" />
      <span class="long">{{ siteInfo.title }}</span>
      <span class="short">{{ siteInfo.titleShortform }}</span>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { useInternalRoute } from '~/datocms/useInternalRoute';
import { useGlobalData } from '~/datocms/useGlobalData';

const { getRoute } = useInternalRoute();
const { siteInfo } = useGlobalData();
</script>

<style scoped lang="scss">
.home-link {
  container-type: inline-size;

  > .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 1rem;

    > .short {
      @container (min-width: 585px) {
        display: none;
      }
    }

    > .long {
      @container (max-width: 584px) {
        display: none;
      }
    }
  }
}
</style>
